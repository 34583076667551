import request from '@/utils/request'

export const userInfo = () =>
    request({
        url: "/promote/user-info",
        method: "post",
        data: {}
    })

export const changeMobile = (data) =>
    request({
        url: "/promote/change-mobile",
        method: "post",
        data
    })

export const changeSettlement = (data) =>
    request({
        url: "/promote/change-settlement",
        method: "post",
        data
    })

export const changePassword = (data) =>
    request({
        url: "/promote/change-password",
        method: "post",
        data
    })

export const changeSecondPwd = (data) =>
    request({
        url: "/promote/change-second-pwd",
        method: "post",
        data
    })
export const changeUserDiscountStatus = (recharge_discount_status) =>
    request({
        url: "/promote/change-status",
        method: "post",
        data: {recharge_discount_status}
    })
// 协议内容详情
export const agreementDetails = () =>
    request({
        url: "/promote/agreement-details",
        method: "post",
        data: {}
    })
// cps协议提交
export const addAgreement = (data) =>
    request({
        url: "/promote/add-agreement",
        method: "post",
        data
    })
export const getDownUrl = () =>
    request({
        url: '/promote/get-down-url',
        method: 'post',
        data:{}
    })
