// +-------------------------------------------------------------
// | Created by WebStorm.
// +-------------------------------------------------------------
// | User: msmile
// +-------------------------------------------------------------
// | Date: 2022/9/14
// +-------------------------------------------------------------
// |Time: 12:07
// +-------------------------------------------------------------
export default [
    {
        path: "/notice/list",
        name: "noticeList",
        meta: {
            title: "公告通知",
            menu:true,
        },
        component: () => import("@/views/notice/List.vue")
    },
    {
        path: "/notice/detail/:notice_id",
        name: "noticeDetail",
        meta: {
            title: "公告通知详情",
            menu:true,
        },
        component: () => import("@/views/notice/Detail.vue")
    }
]
