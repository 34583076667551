import axios from 'axios';
import qs from 'qs';
import {makeSign} from "./make";
import router from "../router";
import {Toast} from "vant";
import store from "@/store";

const service = axios.create({
	baseURL: process.env.VUE_APP_CPS_API_BASE_URL,
	timeout: 5000,
	headers: {
		'Content-Type': "application/x-www-form-urlencoded"
	},
});


service.interceptors.request.use(
	config => {
		if (config.method === 'get') {
			config.params.sign = makeSign(config.params)
		} else if (config.method === 'post') {
			config.data.sign = makeSign(config.data) // 参数签名
			config.data = qs.stringify(config.data) // 转form表单
		}
		config.headers.token = localStorage.getItem('token')
		config.headers.user_id = localStorage.getItem('uid')
		return config;
	},
	error => {
		console.log(error);
		return Promise.reject();
	}
)

service.interceptors.response.use(
	response => {
		if (response.status === 200) {
			if (response.data.code === 888) {
				localStorage.removeItem('token')
				localStorage.removeItem('uid')
				router.push('/login')
			}
			if (response.headers['content-disposition']) {
				const disposition = response.headers['content-disposition'];
				let fileName = decodeURI(escape(disposition.split('filename=')[1].split(';')[0]))

				var blob = new Blob([response.data], {type: response.headers['content-type']})
				var downloadElement = document.createElement('a');
				var href = window.URL.createObjectURL(blob); // 创建下载的链接
				downloadElement.href = href;
				downloadElement.download = fileName; // 下载后文件名
				document.body.appendChild(downloadElement);
				downloadElement.click(); // 点击下载
				document.body.removeChild(downloadElement); // 下载完成移除元素
				window.URL.revokeObjectURL(href); // 释放掉blob对象

				return response.data
			}
			if (response.data.code === 870 || response.data.code === 871) {
				store.commit('updateSignStatus',response.data.code);
				return Promise.reject(response.data)
			}
			if (response.data.code !== 200) {
				Toast(response.data.message);
				return Promise.reject(response.data.message)
			}
			return response.data;
		} else {
			Toast("系统错误");
			return Promise.reject('请求失败');
		}
	},
	error => {
		console.log(error);
		Toast("网络请求失败");
		return Promise.reject('请求失败');
	}
)

export default service;
