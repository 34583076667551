// +-------------------------------------------------------------
// | Created by WebStorm.
// +-------------------------------------------------------------
// | User: msmile
// +-------------------------------------------------------------
// | Date: 2022/8/29
// +-------------------------------------------------------------
// |Time: 15:41
// +-------------------------------------------------------------
import {createStore} from "vuex";
import {userInfo} from "@/api/promote";
import {stsToken} from "@/api/tool";
import {getTime, utcToTimestamp} from "@/utils/time";

export default createStore({
    state:{
        tabBarActive:localStorage.getItem('tabBarActive'),
        theme:'',
        noticeStatus:true,
        userInfo: [],
        signStatus:false,
        // 阿里云上传
        stsToken: {
            SecurityToken: '',
            AccessKeyId: '',
            AccessKeySecret: '',
            Expiration: '',
        },
    },
    mutations:{
        updateUserInfo(state,data){
            state.userInfo = data
        },
        updateNoticeStatus(state){
            state.noticeStatus = false
        },
        updateStsToken(state,data){
            state.stsToken.SecurityToken = data.SecurityToken;
            state.stsToken.AccessKeyId = data.AccessKeyId;
            state.stsToken.AccessKeySecret = data.AccessKeySecret;
            state.stsToken.Expiration = utcToTimestamp(data.Expiration);
        },
        changeTabBar(state,data){
            localStorage.setItem('tabBarActive', data);
            state.tabBarActive = data
        },
        logout(state) {
            localStorage.removeItem('token')
            localStorage.removeItem('uid')
            state.userInfo = [];
            state.tabBarActive = '';
            state.noticeStatus = true;
            state.signStatus = false
        },
        updateSignStatus(state,data) {
            state.signStatus = data
        },
    },
    getters: {
        async stsToken(state) {
            // stsToken存在 且当前时间在有效期内
            if (state.stsToken.AccessKeyId && (getTime() + 300) < state.stsToken.Expiration) {
                return state.stsToken
            }
            await stsToken().then(res => {
                state.stsToken.SecurityToken = res.data.Credentials.SecurityToken;
                state.stsToken.AccessKeyId = res.data.Credentials.AccessKeyId;
                state.stsToken.AccessKeySecret = res.data.Credentials.AccessKeySecret;
                state.stsToken.Expiration = utcToTimestamp(res.data.Credentials.Expiration);
            })
            return state.stsToken
        },
    },
    actions:{
        getUserInfo({commit}){
            return userInfo().then(({data})=>{
                commit('updateUserInfo',data)
            })
        },
        getStsToken({commit}){
            return stsToken().then(res => {
                commit('updateStsToken',res.data.Credentials)
            })
        }
    },
    strict:true
})
