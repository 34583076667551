import { useStore } from "vuex";
import { computed, onMounted } from "vue";
import { isMobile } from "@/utils/devices";
export default {
  name: 'App',

  setup() {
    const store = useStore();
    const theme = computed(() => {
      return store.theme;
    });
    onMounted(() => {
      if (!isMobile() && process.env.VUE_APP_PC_ADAPTER == 'true') {
        location.href = process.env.VUE_APP_CPS_PC_URL;
      }
    });
    return {
      theme
    };
  }

};