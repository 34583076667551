// +-------------------------------------------------------------
// | Created by WebStorm.
// +-------------------------------------------------------------
// | User: msmile
// +-------------------------------------------------------------
// | Date: 2022/8/29
// +-------------------------------------------------------------
// |Time: 15:34
// +-------------------------------------------------------------
import {createRouter, createWebHistory} from "vue-router";
import Game from "@/router/module/game";
import Promote from "@/router/module/promote";
import Recharge from "@/router/module/recharge";
import Profit from "@/router/module/profit";
import Child from "@/router/module/child";
import Data from "@/router/module/data";
import Notice from "@/router/module/notice";

const routes = [
    {
        path: "/",
        redirect: "/dashboard"
    },
    {
        path: '/login',
        name: "cpsLogin",
        meta: {
            title: "登录"
        },
        component: () => import('@/views/Login')
    },
    {
        path: "/index",
        name: "cpsIndex",
        meta: {
            title: "首页",
            menu:true,
        },
        component: () => import("@/views/Index"),
        children:[
            {
                path: "/dashboard",
                name: "Dashboard",
                meta: {
                    title: "系统首页",
                    menu:true,
                },
                component: () => import ("@/views/Dashboard.vue")
            },
            {
                path: "/sham-search",
                name: "cpsShamSearch",
                meta: {
                    title: "钓鱼库",
                    menu:true,
                },
                component: () => import("@/views/data/ShamSearch")
            },
            ...Recharge,
            ...Child,
            ...Profit,
            ...Game,
            ...Promote,
            ...Data,
            ...Notice,
        ]
    },
]
const router = createRouter({
    history:createWebHistory(),
    routes
})

// 退出后路由重置
export function resetRouter() {
    const newRouter = createRouter({
        history: createWebHistory(),
        routes
    })
    router.matcher = newRouter.matcher // reset router
}

router.beforeEach((to,from,next)=>{
    document.title = `游戏友${to.meta.title} | 游戏友推广平台`
    // 判断是否登陆
    const token = localStorage.getItem('token');
    const uid = localStorage.getItem('uid');
    if ((!uid || !token) && to.path !== '/login') {
        next('/login');
    } else {
        next();
    }
})
export default router
