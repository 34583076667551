// +-------------------------------------------------------------
// | Created by WebStorm.
// +-------------------------------------------------------------
// | User: msmile
// +-------------------------------------------------------------
// | Date: 2022/8/31
// +-------------------------------------------------------------
// |Time: 16:02
// +-------------------------------------------------------------
export default [
    {
        path: "/promote/change-mobile",
        name: "changeMobile",
        meta: {
            title: "基本信息",
            menu:true,
        },
        component: () => import("@/views/promote/changeMobile.vue")
    },
    {
        path: "/promote/change-settlement",
        name: "changeSettlement",
        meta: {
            title: "结算信息",
            menu:true,
        },
        component: () => import("@/views/promote/changeSettlement.vue")
    },
    {
        path: "/promote/change-password",
        name: "changePassword",
        meta: {
            title: "修改密码",
            menu:true,
        },
        component: () => import("@/views/promote/changePassword.vue")
    },
    {
        path: "/promote/change-second-pass",
        name: "changeSecondPass",
        meta: {
            title: "修改二级密码",
            menu:true,
        },
        component: () => import("@/views/promote/changeSecondPass.vue")
    },
    {
        path: "/promote/agreement-sign",
        name: "AgreementSign",
        meta: {
            title: "渠道协议",
            menu:true,
        },
        component: () => import("@/views/promote/AgreementSign.vue")
    }
]
