// +-------------------------------------------------------------
// | Created by WebStorm.
// +-------------------------------------------------------------
// | User: msmile
// +-------------------------------------------------------------
// | Date: 2022/9/8
// +-------------------------------------------------------------
// |Time: 17:41
// +-------------------------------------------------------------
export default [
    {
        path: "/profit/exchange",
        name: "profitExchange",
        meta: {
            title: "收益兑换",
            menu:true,
        },
        component: () => import("@/views/profit/Exchange.vue")
    },
    {
        path: "/profit/withdrawal",
        name: "profitWithdrawal",
        meta: {
            title: "收益提现",
            menu:true,
        },
        component: () => import("@/views/profit/Withdrawal.vue")
    },
    {
        path: "/profit/settlement-record",
        name: "profitSettlementRecord",
        meta: {
            title: "直充收益",
            menu:true,
        },
        component: () => import("@/views/profit/settlementRecord.vue")
    },
    {
        path: "/profit/withdrawal-record",
        name: "profitWithdrawalRecord",
        meta: {
            title: "收益支出",
            menu:true,
        },
        component: () => import("@/views/profit/withdrawalRecord.vue")
    },
]
